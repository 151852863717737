export default [
  {
    path: '/user-auth',
    name: 'user-auth',
    meta: {
      requiresAuth: true,
      permission: 'user-auth',
      title: 'Autenticación',
      module: 'user-auth',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-account-details'
    },
    component: () =>
      import(
        /* webpackChunkName: "user-auth" */ '@/views/user-auth/UserAuthLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'pending',
        meta: {
          adminLayoutNav: true,
          permission: 'user-auth/pending',
          requiresAuth: true,
          layout: 'admin',
          title: 'Pendientes',
          module: 'user-auth',
          icon: 'mdi-bell-alert',
          socket: true,
          socketValueKey: 'userAuth.userAuthDocumentCount.pending'
        },
        component: () =>
          import(
            /* webpackChunkName: "user-auth-pending" */ '@/views/user-auth/UserAuthPending.vue'
          )
      },
      {
        path: 'approved',
        name: 'approved',
        meta: {
          adminLayoutNav: true,
          permission: 'user-auth/approved',
          requiresAuth: true,
          layout: 'admin',
          title: 'Aprobados',
          module: 'user-auth',
          icon: 'mdi-account-check'
        },
        component: () =>
          import(
            /* webpackChunkName: "user-auth-approved" */ '@/views/user-auth/UserAuthApproved.vue'
          )
      },
      {
        path: 'rejected',
        name: 'rejected',
        meta: {
          adminLayoutNav: true,
          permission: 'user-auth/rejected',
          requiresAuth: true,
          layout: 'admin',
          title: 'Rechazados',
          module: 'user-auth',
          icon: 'mdi-account-remove'
        },
        component: () =>
          import(
            /* webpackChunkName: "user-auth-rejected" */ '@/views/user-auth/UserAuthRejected.vue'
          )
      },
      {
        path: 'duplicated',
        name: 'duplicated',
        meta: {
          adminLayoutNav: true,
          permission: 'user-auth/duplicated',
          requiresAuth: true,
          layout: 'admin',
          title: 'Cédulas duplicadas',
          module: 'user-auth',
          icon: 'mdi-account-group-outline',
          socket: true,
          socketValueKey: 'userAuth.userAuthDocumentCount.duplicated'
        },
        component: () =>
          import(
            /* webpackChunkName: "user-auth-duplicated" */ '@/views/user-auth/UserAuthDuplicated.vue'
          )
      },
      {
        path: 'face-duplicated',
        name: 'face-duplicated',
        meta: {
          adminLayoutNav: true,
          permission: 'user-auth/face-duplicated',
          requiresAuth: true,
          layout: 'admin',
          title: 'Rostros duplicados',
          module: 'user-auth',
          icon: 'mdi-account-group-outline',
          socket: true,
          socketValueKey: 'userAuth.userAuthDocumentCount.faceDuplicated'
        },
        component: () =>
          import(
            /* webpackChunkName: "user-auth-face-duplicated" */ '@/views/user-auth/UserAuthFaceDuplicated.vue'
          )
      },
      {
        path: 'config',
        name: 'config',
        meta: {
          adminLayoutNav: true,
          permission: 'user-auth/config',
          requiresAuth: true,
          layout: 'admin',
          title: 'Configuración',
          module: 'user-auth',
          icon: 'mdi-cogs'
        },
        component: () =>
          import(
            /* webpackChunkName: "user-auth-config" */ '@/views/user-auth/UserAuthConfig.vue'
          )
      }
    ]
  }
];
