const state = {
    filters: {
        referralsConfigOptions: {
            value: 'General',
            options: ['General', 'Detalles de la app', 'Ciudades'],
            select: 0
        },
    },
};
  
const mutations = {
    SET_FILTERS(state, filters) {
        state.filters = { ...state.filters, ...filters };
    },
};
  
const actions = {
    setFilters({ commit }, filters) {
        commit("SET_FILTERS", filters);
    },
};
  
const getters = {
    filters: (state) => state.filters,
};
  
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
  