export default [
  {
    path: '/zendesk',
    name: 'zendesk',
    meta: {
      requiresAuth: true,
      permission: 'zendesk/config',
      title: 'Zendesk',
      module: 'zendesk',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-account-multiple'
    },
    component: () =>
      import(
        /* webpackChunkName: "zendesk" */ '@/views/zendesk/ZendeskLayout.vue'
      ),
    children: [
      {
        path: 'config',
        name: 'zendesk-config',
        meta: {
          adminLayoutNav: true,
          permission: 'zendesk/config',
          requiresAuth: true,
          layout: 'admin',
          title: 'Configuraciones',
          module: 'zendesk',
          icon: 'mdi-cog'
        },
        component: () =>
          import(
            /* webpackChunkName: "zendesk-config" */ '@/views/zendesk/ZendeskConfig.vue'
          )
      }
    ]
  }
];
