export default [
    {
        path: '/referrals',
        name: 'referrals',
        meta: {
            requiresAuth: true,
            permission: null, // permission
            title: 'Referidos',
            module: 'referrals',
            layout: 'admin',
            adminLayoutNav: true,
            icon: 'mdi-account-group'
        },
        component: () =>
            import(/* webpackChunkName: "referrals" */ '@/views/referrals/ReferralsLayout.vue'),
        children: [
            {
              path: 'list',
              name: 'referrals-list',
              meta: {
                adminLayoutNav: true,
                permission: '',
                requiresAuth: true,
                layout: 'admin',
                title: 'Lista de referidos (config)',
                module: 'referrals',
                icon: 'mdi-view-list'
              },
              component: () =>
                import(
                  /* webpackChunkName: "referrals-config" */ '@/views/referrals/ReferAuthenticationlList.vue'
                )
            },
            {
              path: 'config',
              name: 'referrals-config',
              meta: {
                adminLayoutNav: true,
                permission: '',
                requiresAuth: true,
                layout: 'admin',
                title: 'Nueva configuración',
                module: 'referrals',
                icon: 'mdi-plus'
              },
              component: () =>
                import(
                  /* webpackChunkName: "referrals-config" */ '@/views/referrals/ReferralsConfig.vue'
                )
            },
        ]
    }
]