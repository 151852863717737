<template>
  <component :is="resolveLayoutVariant">
    <LoadingOverlay :loading="app.isLoadingOverlay" />
    <v-slide-x-transition mode="out-in">
      <router-view></router-view>
    </v-slide-x-transition>
  </component>
</template>

<script>
  import BlankLayout from '@/layouts/blank/BlankLayout.vue';
  import AdminLayout from '@/layouts/admin/AdminLayout.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'App',
    components: {
      BlankLayout,
      AdminLayout,
      LoadingOverlay
    },
    computed: {
      ...mapState(['app', 'auth']),

      /**
       * Determines which layout to use based on the route's meta property.
       * @returns {string} The layout component name ('AdminLayout' or 'BlankLayout').
       */
      resolveLayoutVariant() {
        return this.$route.meta.layout === 'admin'
          ? 'AdminLayout'
          : 'BlankLayout';
      }
    },
    methods: {
      ...mapActions('userAuth', ['setUserAuthDocumentCount']),

      /**
       * Checks if the user has authentication-related permissions.
       * @param {Object} user - The user object containing authentication details.
       * @returns {boolean} True if the user has the necessary permissions.
       */
      hasUserAuthPermissions(user) {
        const { isLoggedIn, userDetails } = user || {};
        return (
          isLoggedIn &&
          (userDetails.adminType === 0 ||
            userDetails.permissions?.includes('user-auth'))
        );
      },

      /**
       * Handles changes to the authentication state.
       * Calls the user auth documents count fetch method if the user has valid permissions.
       * @param {Object} newAuth - The new authentication state.
       * @returns {Promise<void>}
       */
      async handleAuthChange(newAuth) {
        if (!newAuth || !this.hasUserAuthPermissions(newAuth.user)) return;

        const { isLoggedIn, token } = newAuth.user;

        if (isLoggedIn && token) {
          await this.fetchUserAuthDocumentsCount();
        }
      },

      /**
       * Fetches the user authentication documents count from the server.
       * Updates the Vuex store with the count.
       * @returns {Promise<void>}
       */
      async fetchUserAuthDocumentsCount() {
        try {
          const response = await this.$axios.get(
            '/user_authentication/get_user_auth_documents_count'
          );
          const { pending = 0, duplicated = 0, faceDuplicated = 0 } =
            response?.data?.user_auth_details || {};
          this.setUserAuthDocumentCount({
            fetched: true,
            pending,
            duplicated,
            faceDuplicated
          });
        } catch (error) {
          console.error('Error fetching user auth documents count:', error);
        }
      },

      /**
       * Handles the 'user_auth_detail' event received via socket.
       * Re-evaluates the authentication state.
       * @param {Object} data - Data received from the 'user_auth_detail' socket event.
       * @returns {Promise<void>}
       */
      async onUserAuthDetail(data) {
        console.log('Received user_auth_detail data:', data);
        if (this.auth?.user?.isLoggedIn && this.$route.meta.requiresAuth) {
          this.handleAuthChange(this.auth);
        }
      }
    },
    watch: {
      auth: {
        handler: 'handleAuthChange',
        deep: true
      }
    },
    sockets: {
      /**
       * Handles socket connection event.
       */
      connect() {
        console.info('Socket connected');
      },

      /**
       * Handles the 'user_auth_detail' socket event and calls the corresponding method.
       * @param {Object} data - Data received from the socket event.
       */
      user_auth_detail(data) {
        this.onUserAuthDetail(data);
      }
    },
    created() {
      this.$vuetify.theme.dark = this.app.isThemeDark;

      if (this.auth?.user?.isLoggedIn && this.$route.meta.requiresAuth) {
        this.handleAuthChange(this.auth);
      }
    }
  };
</script>

<style>
  /* Custom scrollbars */
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  ::-webkit-scrollbar {
    background-color: white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000421;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-corner {
    background-color: #d8d8d8;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
</style>
