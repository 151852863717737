import axios from '../plugins/axios';

/**
 * HttpService
 *
 * A HTTP abstraction layer built on top of a globally configured axios instance,
 * primarily intended for use with the /V2 endpoints of the backend.
 *
 * This service provides methods for making GET, POST, PUT, PATCH, and DELETE HTTP requests.
 * It automatically processes responses by checking the `success` flag and extracting the `data` property.
 * It also supports:
 *  - Sending FormData payloads (automatically removing the default 'Content-Type' header).
 *  - Overriding default configurations per request by passing extra parameters (such as a custom error message or a custom baseURL).
 *
 * @example
 * // Install the plugin in Vue:
 * import HttpServicePlugin from './http';
 * Vue.use(HttpServicePlugin);
 *
 * // Usage in a Vue component:
 * async function fetchItems() {
 *   try {
 *     const data = await this.$http.get('/api/items', {}, { customError: 'Unable to fetch items', baseURL: 'https://api.example.com' });
 *     console.log('Fetched items:', data);
 *   } catch (error) {
 *     console.error('Error fetching items:', error);
 *   }
 * }
 *
 * @class HttpService
 */
class HttpService {
  constructor(axiosInstance) {
    this.axios = axiosInstance;
  }

  /**
   * GET request: Performs a GET request.
   *
   * @example
   * async function fetchItems() {
   *   try {
   *     // Usage with extraParams: override baseURL and set custom error message
   *     const extraParams = {
   *       baseURL: 'https://api.alternative.com',
   *       customError: 'Custom error: Unable to fetch items'
   *     };
   *     const data = await this.$http.get('/api/items', {}, extraParams);
   *     console.log('Items:', data);
   *   } catch (error) {
   *     console.error('Error fetching items:', error);
   *   }
   * }
   *
   * @param {string} url - Request URL.
   * @param {object} [config={}] - Optional configuration.
   * @param {object} [extraParams={}] - Extra parameters:
   *                                    - customError: {string} Custom error message.
   *                                    - baseURL: {string} Override baseURL for this request.
   * @returns {Promise} - Resolves with response data on success.
   */
  async get(url, config = {}, extraParams = {}) {
    try {
      const newConfig = this._prepareConfig(null, config, extraParams);
      const response = await this.axios.get(url, newConfig);
      return this._handleResponse(response, extraParams);
    } catch (error) {
      throw error;
    }
  }

  /**
   * POST request: Performs a POST request.
   * Handles payloads that may be a FormData instance.
   *
   * @example
   * // With JSON payload and extraParams:
   * async function createItem() {
   *   try {
   *     const payload = { name: 'New Item' };
   *     const extraParams = {
   *       customError: 'Custom error: Item could not be created'
   *     };
   *     const data = await this.$http.post('/api/items', payload, {}, extraParams);
   *     console.log('Item created:', data);
   *   } catch (error) {
   *     console.error('Error creating item:', error);
   *   }
   * }
   *
   * // With FormData payload:
   * async function uploadFile(fileInput) {
   *   try {
   *     const formData = new FormData();
   *     formData.append('file', fileInput.files[0]);
   *     const extraParams = {
   *       baseURL: 'https://api.upload.com'
   *     };
   *     const data = await this.$http.post('/api/upload', formData, {}, extraParams);
   *     console.log('File uploaded:', data);
   *   } catch (error) {
   *     console.error('Upload error:', error);
   *   }
   * }
   *
   * @param {string} url - Request URL.
   * @param {object|FormData} payload - Data to send (can be FormData).
   * @param {object} [config={}] - Optional configuration.
   * @param {object} [extraParams={}] - Extra parameters.
   * @returns {Promise} - Resolves with response data on success.
   */
  async post(url, payload, config = {}, extraParams = {}) {
    try {
      const newConfig = this._prepareConfig(payload, config, extraParams);
      const response = await this.axios.post(url, payload, newConfig);
      return this._handleResponse(response, extraParams);
    } catch (error) {
      throw error;
    }
  }

  /**
   * PUT request: Performs a PUT request.
   * Handles payloads that may be a FormData instance.
   *
   * @example
   * async function updateItem() {
   *   try {
   *     const payload = { name: 'Updated Item' };
   *     const extraParams = {
   *       customError: 'Custom error: Unable to update item'
   *     };
   *     const data = await this.$http.put('/api/items/123', payload, {}, extraParams);
   *     console.log('Item updated:', data);
   *   } catch (error) {
   *     console.error('Error updating item:', error);
   *   }
   * }
   *
   * @param {string} url - Request URL.
   * @param {object|FormData} payload - Data to send (can be FormData).
   * @param {object} [config={}] - Optional configuration.
   * @param {object} [extraParams={}] - Extra parameters.
   * @returns {Promise} - Resolves with response data on success.
   */
  async put(url, payload, config = {}, extraParams = {}) {
    try {
      const newConfig = this._prepareConfig(payload, config, extraParams);
      const response = await this.axios.put(url, payload, newConfig);

      return this._handleResponse(response, extraParams);
    } catch (error) {
      throw error;
    }
  }

  /**
   * PATCH request: Performs a PATCH request.
   * Handles payloads that may be a FormData instance.
   *
   * @example
   * async function patchItem() {
   *   try {
   *     const payload = { name: 'Partially Updated Item' };
   *     const extraParams = {
   *       customError: 'Custom error: Item could not be patched'
   *     };
   *     const data = await this.$http.patch('/api/items/123', payload, {}, extraParams);
   *     console.log('Item patched:', data);
   *   } catch (error) {
   *     console.error('Error patching item:', error);
   *   }
   * }
   *
   * @param {string} url - Request URL.
   * @param {object|FormData} payload - Data to send (can be FormData).
   * @param {object} [config={}] - Optional configuration.
   * @param {object} [extraParams={}] - Extra parameters.
   * @returns {Promise} - Resolves with response data on success.
   */
  async patch(url, payload, config = {}, extraParams = {}) {
    try {
      const newConfig = this._prepareConfig(payload, config, extraParams);
      const response = await this.axios.patch(url, payload, newConfig);
      return this._handleResponse(response, extraParams);
    } catch (error) {
      throw error;
    }
  }

  /**
   * DELETE request: Performs a DELETE request.
   *
   * @example
   * async function deleteItem() {
   *   try {
   *     const extraParams = {
   *       customError: 'Custom error: Item could not be deleted'
   *     };
   *     const data = await this.$http.delete('/api/items/123', {}, extraParams);
   *     console.log('Item deleted:', data);
   *   } catch (error) {
   *     console.error('Error deleting item:', error);
   *   }
   * }
   *
   * @param {string} url - Request URL.
   * @param {object} [config={}] - Optional configuration.
   * @param {object} [extraParams={}] - Extra parameters.
   * @returns {Promise} - Resolves with response data on success.
   */
  async delete(url, config = {}, extraParams = {}) {
    try {
      const newConfig = this._prepareConfig(null, config, extraParams);
      const response = await this.axios.delete(url, newConfig);
      return this._handleResponse(response, extraParams);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Processes the backend response, which is expected to always return an object
   * with properties: { success, data, error_message }.
   *
   * If the response is not successful, throws an error using the custom error
   * message provided via extraParams (if any), otherwise uses the default error message.
   *
   * @example
   *  Given response.data = { success: false, error_message: 'Not found' }
   *  and extraParams = { customError: 'Custom not found error' },
   *  _handleResponse(response, extraParams) will throw an Error with message:
   *  'Custom not found error'.
   *
   * @param {object} response - Axios response object.
   * @param {object} [extraParams={}] - Extra parameters for handling the response.
   * @returns {object} - Returns the data if success is true.
   * @throws {Error} - Throws an error if success is false.
   */
  _handleResponse(response, extraParams = {}) {
    const {
      success = false,
      data,
      error_message: errorMessage
    } = response?.data;
    const { customError } = extraParams;
    if (!success) {
      throw new Error(customError || errorMessage || 'Server response error');
    }
    return data;
  }

  /**
   * Prepares the configuration for requests.
   * If the payload is a FormData instance, it removes the default 'Content-Type'
   * header so that the browser can set the proper boundary.
   *
   * Additionally, if extraParams contains a `baseURL`, that value will override
   * the default baseURL for that request.
   *
   * @example
   * async function sendFormData(fileInput) {
   *   try {
   *     const formData = new FormData();
   *     formData.append('file', fileInput.files[0]);
   *     const config = { headers: { 'Content-Type': 'application/json' } };
   *     const extraParams = {
   *       baseURL: 'https://api.upload.com'
   *     };
   *     // Set the baseURL from extraParams.
   *     const data = await this.$http.post('/api/upload', formData, config, extraParams);
   *     console.log('Upload response:', data);
   *   } catch (error) {
   *     console.error('Upload error:', error);
   *   }
   * }
   *
   * @param {object|FormData} payload - Data to send.
   * @param {object} [config={}] - Existing configuration.
   * @param {object} [extraParams={}] - Extra parameters.
   * @returns {object} - The modified configuration for axios.
   */
  _prepareConfig(payload, config = {}, extraParams = {}) {
    const newConfig = { ...config };
    if (extraParams.baseURL) {
      newConfig.baseURL = extraParams.baseURL;
    }
    return newConfig;
  }
}

const http = new HttpService(axios);

export default {
  /**
   * Vue plugin install function.
   * Installs the HTTP service on the Vue prototype as $http.
   *
   * @param {Vue} Vue - The Vue constructor.
   */
  install(Vue) {
    Vue.prototype.$http = http;
  }
};
