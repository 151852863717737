export default [
    {
      path: "/users-debts",
      name: "users-debts",
      meta: {
        requiresAuth: true,
        permission: "users-debts",
        title: "Deudas de usuarios",
        module: "users-debts",
        layout: "admin",
        adminLayoutNav: true,
        icon: "mdi-cash-clock",
      },
      component: () =>
        import(
          /* webpackChunkName: "users-debts" */ "@/views/usersDebts/UsersDebtsLayout.vue"
        ),
      children: [
        {
          path: "",
          name: "debts-list",
          meta: {
            adminLayoutNav: true,
            permission: "users-debts-list",
            requiresAuth: true,
            layout: "admin",
            title: "Listado de deudas de usuarios",
            module: "users-debts",
            icon: "mdi-account-credit-card",
          },
          component: () =>
            import(
              /* webpackChunkName: "users-debts" */ "@/views/usersDebts/UsersDebtsList.vue"
            ),
        }
      ],
    },
  ];
  