export const statusApplicant = [
  {
    text: 'Aprobado',
    value: 1,
  },
  {
    text: 'No aprobado',
    value: 0,
  },
  {
    text: 'Bloqueado',
    value: 2,
  },
  {
    text: 'Suspendido',
    value: 3,
  }
];

export const statusProcess = [
  {
    text: 'Registro de proveedor',
    value: 'providerRegistered',
    color: '#C2C2C2',
    textColor: 'black',
  },
  {
    text: 'Registro de vehículo',
    value: 'vehicleRegistered',
    color: '#C2C2C2',
    textColor: 'black',
  },
  {
    text: 'Documentos en verificación',
    value: 'documentVerifying',
    color: '#C2C2C2',
    textColor: 'black',
  },
  {
    text: 'Inspección virtual pendiente',
    value: 'virtualInspectionPending',
    color: '#C2C2C2',
    textColor: 'black',
  },
  {
    text: 'Inspección virtual en revisión',
    value: 'virtualInspectionReviewing',
    color: '#FFA41C',
    textColor: 'black',
  },
  {
    text: 'Inspección virtual en reparaciones',
    value: 'virtualInspectionRepairing',
    color: '#FFA41C',
    textColor: 'black',
  },
  {
    text: 'Inspección virtual rechazada',
    value: 'virtualInspectionRejected',
    color: '#DE2568',
    textColor: 'black',
  },
  {
    text: 'Vídeo entrevista pendiente',
    value: 'interviewVideoPending',
    color: '#C2C2C2',
    textColor: 'black',
  },
  {
    text: 'Vídeo entrevista en revisión',
    value: 'interviewVideoReviewing',
    color: '#3BD4AE',
    textColor: 'black',
  },
  {
    text: 'Vídeo entrevista rechazada',
    value: 'interviewVideoRejected',
    color: '#DE2568',
    textColor: 'black',
  },
  {
    text: 'RCV pendiente',
    value: 'rcvPending',
    color: '#C2C2C2',
    textColor: 'black',
  },
  {
    text: 'Inducción pendiente',
    value: 'inductionPending',
    color: '#C2C2C2',
    textColor: 'black',
  },
  {
    text: 'Inspección presencial por agendar',
    value: 'inPersonInspectionSchedulePending',
    color: '#C2C2C2',
    textColor: 'black',
  },
  {
    text: 'Inspección presencial por asistir',
    value: 'inPersonInspectionAttending',
    color: '#FFA41C',
    textColor: 'black',
  },
  {
    text: 'Inspección presencial en reparaciones',
    value: 'inPersonInspectionRepairing',
    color: '#FFA41C',
    textColor: 'black',
  },
  {
    text: 'Inspección presencial rechazado',
    value: 'inPersonInspectionRejected',
    color: '#DE2568',
    textColor: 'black',
  },
  {
    text: 'Inspección presencial vehículo rechazado',
    value: 'inPersonInspectionVehicleRejected',
    color: '#DE2568',
    textColor: 'black',
  },
  {
    text: 'Inspección presencial pre-aprobada',
    value: 'inPersonInspectionPreApproved',
    color: '#3BD4AE',
    textColor: 'black',
  },
  {
    text: 'Inspección presencial aprobada',
    value: 'approved',
    color: '#3BD4AE',
    textColor: 'black',
  },
  {
    text: 'Proceso saltado',
    value: 'skipped',
    color: '#3BD4AE',
    textColor: 'black',
  },
];

export const statusProcessOverview = [
  {
    text: 'Registro de proveedor',
    value: 'providerRegistered',
  },
  {
    text: 'Registro de vehículo',
    value: 'vehicleRegistered',
  },
  {
    text: 'Documentos en verificación',
    value: 'documentVerifying',
  },
  {
    text: 'Inspección virtual',
    value: 'virtualInspection',
  },
  {
    text: 'Vídeo entrevista',
    value: 'interviewVideo',
  },
  {
    text: 'Inducción pendiente',
    value: 'inductionPending',
  },
  {
    text: 'Inspección presencial',
    value: 'inPersonInspection',
  },
];

export const responsible = [
  {
    text: 'Conductor',
    value: 'provider',
  },
  {
    text: 'Admin',
    value: 'admin',
  },
  {
    text: 'Ridery',
    value: 'ridery',
  },
];

export const requestPhotos = [
  {
    text: 'Foto frontal',
    value: 'front',
  },
  {
    text: 'Parte trasera',
    value: 'rear',
  },
  {
    text: 'Maletero abierto',
    value: 'trunk',
  },
  {
    text: 'Lateral derecho',
    value: 'sideLeft',
  },
  {
    text: 'Lateral izquierdo',
    value: 'sideRight',
  },
  {
    text: 'Asientos delanteros',
    value: 'frontSeats',
  },
  {
    text: 'Asientos traseros',
    value: 'rearSeats',
  },
  {
    text: 'Tablero',
    value: 'dashboard',
  },
];

export const statusVirtualInspection = {
  FOR_REPAIR: 'forRepair',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}

export const statusInPersonInspection = {
  FOR_REPAIR: 'forRepair',
  REJECTED: 'rejected',
  VEHICLE_REJECTED: 'vehicleRejected',
}

export const getResponsible = (value) => {
  return responsible.find((item) => item.value === value)?.text;
}

export const getStatusApplicant = (value) => {
  return statusApplicant.find((item) => item.value === value);
}

export const getStatusProcess = (value, lastStatus) => {
  return statusProcess.find((item) => item.value === value);
}

export const getStatusProcessOverview = (value) => {
  return statusProcessOverview.find((item) => item.value === value)?.text;
}

export const getRequestPhotos = (value) => {
  return requestPhotos.find((item) => item.value === value)?.text;
}