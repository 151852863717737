export default [
  {
    path: '/wallet-history',
    name: 'wallet-history',
    meta: {
      requiresAuth: true,
      permission: 'wallet_history',
      title: 'Historial de Movimientos',
      module: 'wallet-history',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-cash'
    },
    component: () => 
      import(/* webpackChunkName: "polls" */ '@/views/wallet-history/walletHistoryLayout.vue'),
    children: [
      {
        path: '',
        name: 'wallet-history-index',
        meta: {
          requiresAuth: true,
          permission: 'wallet_history',
          title: 'Historial de Movimientos',
          module: 'wallet-history',
          layout: 'admin',
          adminLayoutNav: true,
          icon: 'mdi-wallet'
        },
        component: () => 
          import(/* webpackChunkName: "polls" */ '@/views/wallet-history/walletHistoryIndex.vue'),
      }
    ]
  }
]