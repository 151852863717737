// state

const baseState = {
  filters: {
    date: [],
    sortFilter: {
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Fecha',
          value: 'created_at'
        }
      ],
      optionValue: 'created_at',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Origen',
          value: 'wallet_description'
        },
        {
          text: 'Usuario email',
          value: 'user_email'
        },
        {
          text: 'Conductor email',
          value: 'provider_email'
        },
        {
          text: 'Usuario ID',
          value: 'user_unique_id'
        },
        {
          text: 'Conductor ID',
          value: 'provider_unique_id'
        },
      ],
      optionValue: ''
    },
    typeFilter: {
      options: [
        {
          value: 0,
          text: 'Todos'
        },
        {
          value: 10,
          text: 'Usuarios'
        },
        {
          value: 11,
          text: 'Conductor'
        },
        {
          value: 4,
          text: 'Partner'
        },
        {
          value: 5,
          text: 'Corporativo'
        }
      ],
      optionValue: '0',
    },
    walletPaymentTypeFilter: {
      optionValue: [],
      options: []
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 20
    },
    expandTable: false
  }
};

const state = baseState;

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, user) {
    commit('SET_FILTERS', user);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', baseState);
  }
};

const getters = {
  user: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
