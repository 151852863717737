// store/modules/auth.js

let baseState = {
  filters: {
    date: [],
    tabs: {
      value: 'inPersonInspectionSchedulePending',
      options: [
        { name: 'Pendientes', value: 'inPersonInspectionSchedulePending' },
        { name: 'En reparación', value: 'inPersonInspectionRepairing' },
        { name: 'Pre-aprobados', value: 'inPersonInspectionPreApproved' },
      ],
      select: 0
    },
    sortFilter: {
      options: [
        {
          text: 'ID conductor',
          value: 'provider.unique_id'
        },
        {
          text: 'Pendiente desde',
          value: 'lastStatusCreatedAt'
        },
      ],
      optionValue: 'lastStatusCreatedAt',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'Teléfono',
          value: 'provider.phone'
        },
        {
          text: 'Cédula',
          value: 'provider.document_number'
        },
        {
          text: 'Correo',
          value: 'provider.email'
        },
      ],
      optionValue: ''
    },
    cityFilter: {
      optionValue: [],
      options: [],
      selectAll: false
    },
    fleetFilter: {
      optionValue: [],
      options: [],
      selectAll: false
    },
    fleetSecondaryFilter: {
      optionValue: [],
      options: [],
      selectAll: false
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const state = baseState;

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', baseState);
  }
};

const getters = {
  filters: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
