export default [
  {
    path: '/corporates',
    name: '',
    meta: {
      permission: [
        'list-corporate',
        'corporate-credit-request-list',
        'corporate-credit-request-approve-list'
      ],
      requiresAuth: true,
      title: 'Corporativos',
      module: 'corporates',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-domain',
      isBaseLayout: true
    },
    component: () => import('@/views/corporates/CorporatesLayout.vue'),
    children: [
      {
        path: '',
        name: 'corporates-list',
        meta: {
          adminLayoutNav: true,
          permission: 'list-corporate',
          requiresAuth: true,
          layout: 'admin',
          title: 'Listado',
          module: 'corporates',
          icon: 'mdi-view-list',
          allowedTotalDocumentsForIndexPages: true
        },
        component: () => import('@/views/corporates/CorporatesList.vue')
      },
      {
        path: 'corporate-credit-requests',
        name: 'corporate-credit-request-list',
        meta: {
          adminLayoutNav: true,
          permission: 'corporate-credit-request-list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Solicitudes créditos',
          module: 'corporates',
          icon: 'mdi-cash-multiple'
        },
        component: () => import('@/views/corporates/CreditRequestsList.vue')
      },
      {
        path: 'corporate-credits',
        name: 'corporate-credits-list',
        meta: {
          adminLayoutNav: true,
          permission: 'corporate-credits-list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Créditos aprobados',
          module: 'corporates',
          icon: 'mdi-check-circle'
        },
        component: () =>
          import('@/views/corporates/ApprovedCreditRequestsList.vue')
      },
      {
        path: 'corporate-credits-move-history',
        name: 'corporate-credits-move-history',
        meta: {
          adminLayoutNav: true,
          permission: 'corporate-credit-movements-history',
          requiresAuth: true,
          layout: 'admin',
          title: 'Deudas',
          module: 'corporates',
          icon: 'mdi-history'
        },
        component: () =>
          import('@/views/corporates/CorporateCreditMoveHistory.vue')
      },
      {
        path: 'corporate-settings',
        name: 'corporate-settings',
        meta: {
          adminLayoutNav: true,
          permission: 'corporate_settings_office',
          requiresAuth: true,
          layout: 'admin',
          title: 'Configuración',
          module: 'corporates',
          icon: 'mdi-tools'
        },
        component: () => import('@/views/corporates/CorporateSettings.vue')
      }
    ]
  }
];
