let baseState = {
    filters: {
      sortFilter: {
        options: [
          {
            text: 'ID deuda',
            value: 'unique_id'
          },
          {
            text: 'Tipo de deuda',
            value: 'debt_type'
          },
          {
            text: 'Monto de deuda',
            value: 'debt_amount'
          },
          {
            text: 'Estatus',
            value: 'debt_state'
          },
          {
            text: 'ID del usuario',
            value: 'user_id'
          },
          {
            text: 'Fecha y hora',
            value: 'created_at'
          },
          {
            text: 'ID de la transacción',
            value: 'wallet_history_unique_id'
          }
          
        ],
        optionValue: 'unique_id',
        sortModes: [
          {
            text: 'Ascendente',
            value: 1
          },
          {
            text: 'Descendente',
            value: -1
          }
        ],
        sortModeValue: -1
      },
      searchFilter: {
        searchValue: null,
        options: [
          {
            text: 'ID de la deuda',
            value: 'unique_id'
          },
          {
            text: 'ID usuario',
            value: 'user_unique_id'
          }
        ],
        optionValue: 'unique_id'
      },
      statusFilter: {
        options: [
          {
            text: 'Pagado',
            value: 'PAID',
          },
          {
            text: 'Parcialmente pagado',
            value: 'PARTIAL_PAID',
          },
          {
            text: 'Pendiente',
            value: 'PENDING',
          }
        ],
        optionValue: '',
        selectAll: false,
      },
      debtTypes: {
        options: [],
        optionValue: '',
        selectAll: false,
      },
      date: [],
      pagination: {
        page: 1,
        pages: 1,
        limit: 10
      },
      expandTable: false
    }
  };
  
  const state = baseState;
  
  const mutations = {
    SET_FILTERS(state, filters) {
      state.filters = { ...state.filters, ...filters };
    }
  };
  
  const actions = {
    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', baseState);
    }
  };
  
  const getters = {
    user: (state) => state.filters
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  };
  