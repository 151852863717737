// store/modules/auth.js

const state = {
  filters: {
    date: [],
    sortFilter: {
      options: [
        {
          text: 'Fecha de solicitud',
          value: 'created_at'
        }
      ],
      optionValue: 'created_at',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: 1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Nombre o apellido',
          value: 'fullName'
        },
        {
          text: 'Cédula',
          value: 'userCedula'
        },
        {
          text: 'Teléfono',
          value: 'userPhone'
        }
      ],
      optionValue: ''
    },
    // citiesAutoComplete: {
    //   value: [],
    //   options: [],
    //   selectAll: false,
    // },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  },
  userAuthDocumentCount: {
    fetched: false,
    pending: null,
    duplicated: null,
    faceDuplicated: null
  }
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  SET_DOCUMENT_COUNT(state, count) {
    state.userAuthDocumentCount = { ...state.userAuthDocumentCount, ...count };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }, payload = 1) {
    const originalState = {
      date: [],
      sortFilter: {
        options: [
          {
            text: 'Fecha de solicitud',
            value: 'created_at'
          }
        ],
        optionValue: 'created_at',
        sortModes: [
          {
            text: 'Ascendente',
            value: 1
          },
          {
            text: 'Descendente',
            value: -1
          }
        ],
        sortModeValue: payload
      },
      searchFilter: {
        searchValue: '',
        options: [
          {
            text: 'ID',
            value: 'unique_id'
          },
          {
            text: 'Nombre o apellido',
            value: 'fullName'
          },
          {
            text: 'Cédula',
            value: 'userCedula'
          },
          {
            text: 'Teléfono',
            value: 'userPhone'
          }
        ],
        optionValue: ''
      },
      pagination: {
        page: 1,
        pages: 1,
        limit: 10
      },
      expandTable: false
    };

    commit('SET_FILTERS', originalState);
  },
  setUserAuthDocumentCount({ commit }, count) {
    commit('SET_DOCUMENT_COUNT', count);
  }
};

const getters = {
  user: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
