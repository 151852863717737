import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

import auth from './modules/auth';
import polls from './modules/polls';
import vehicleModels from './modules/vehicleModels';
import paymentMethod from './modules/paymentMethod';
import vehicleFleetAssociation from './modules/vehicleFleetAssociation';
import vehicleFleetAssociationList from './modules/vehicleFleetAssociationList';
import userAuth from './modules/userAuth';
import rcvList from './modules/rcvList';
import shipmentPartners from '../views/api-partners/state/shipmentPartners';
import providers from '../views/providers/state/providers';
import userLoyaltyConfig from './modules/userLoyaltyConfig';
import userLoyaltyPromocodeList from './modules/userLoyaltyPromocodeList';
import userLoyaltyHistoryPoints from './modules/userLoyaltyHistoryPoints';
import userLoyaltyMultiplierList from './modules/userLoyaltyMultiplierList';
import userLoyaltyCashbackList from './modules/userLoyaltyCashbackList';
import userLoyaltyLevelList from './modules/userLoyaltyLevelList';
import pagoMovilRefund from '../views/pago-movil/state/pagoMovilRefund';
import directDebitTransaction from '../views/reports/direct-debit-transactions/state/directDebitTransaction';
import trips from '../views/trips/state/trips';
import tripsHistory from '../views/trips/state/tripsHistory';
import apiPartnersTripFilters from '../views/api-partners/state/apiPartnersTripsFilters';
import apiPartnersTripHistoriesFilters from '../views/api-partners/state/apiPartnersTripHistoriesFilters';
import apiPartnersTripHistoriesNotAcceptedFilters from '../views/api-partners/state/apiPartnersTripHistoriesNotAcceptedFilters';
import corporates from '@/views/corporates/state/corporates';
import prospectSettings from '@/views/prospects/state/prospectSettings';
import prospectOnProcess from '@/views/prospects/state/prospectOnProcess';
import prospectOnVirtualInspection from '@/views/prospects/state/prospectOnVirtualInspection';
import prospectOnVirtualVideoInterview from '@/views/prospects/state/prospectOnVirtualVideoInterview';
import prospectInPersonInspection from '@/views/prospects/state/prospectInPersonInspection';
import addressesCategory from '../views/addresses-category/state/addressesCategory';
import surge from '../views/surge/state/surge';
import bulkPaymentDriver from './modules/bulkPaymentDriver';
import paymentDriverRecord from './modules/paymentDriverRecord';
import exportTask from './modules/exportTask';
import scheduledTask from './modules/scheduledTask';
import cmsDriversCategory from './../views/cms-drivers/state/cmsDriversCategory';
import currencyRateUSDVES from '@/views/currency-rate/state/currencyRateUSDVES';
import currencyRateUSDCOP from '@/views/currency-rate/state/currencyRateUSDCOP';
import currency from './modules/currency';
import pagoMovilBanksList from '../views/pago-movil/state/pagoMovilBankList';
import mobileBankingPaymentMethodConfig from '../views/pago-movil/state/mobileBankingPaymentMethodConfig';
import pagoMovilBankCityList from '../views/pago-movil/state/pagoMovilBankCityList';
import pagoMovilTransactions from '../views/pago-movil/state/pagoMovilTransactions';
import walletHistory from '@/views/wallet-history/state/walletHistory';
import referralsConfig from '@/views/referrals/state/referralsConfig';
import referAuthenticationList from '@/views/referrals/state/referAuthenticationList';
import usersDebts from '@/views/usersDebts/state/usersDebts';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth,
    app: {
      isMiniVariant: state.app.isMiniVariant,
      isThemeDark: state.app.isThemeDark
    },
    userAuth: {
      userAuthDocumentCount: state.userAuth.userAuthDocumentCount
    }
  })
});

export default new Vuex.Store({
  state: {
    app: {
      isLoadingOverlay: false,
      isMiniVariant: false,
      isThemeDark: false,
      totalDocumentsForIndexPagesBreadCrumbs: 0,
      loadingTotalDocumentsForIndexPagesBreadCrumbs: false
    }
  },
  mutations: {
    SET_APP(state, app) {
      state.app = { ...state.app, ...app };
    }
  },
  actions: {
    setApp({ commit }, app) {
      commit('SET_APP', app);
    }
  },
  getters: {
    app: (state) => state.app
  },
  modules: {
    auth,
    polls,
    vehicleModels,
    vehicleFleetAssociation,
    vehicleFleetAssociationList,
    userAuth,
    rcvList,
    shipmentPartners,
    providers,
    paymentMethod,
    userLoyaltyConfig,
    userLoyaltyPromocodeList,
    userLoyaltyHistoryPoints,
    userLoyaltyMultiplierList,
    userLoyaltyCashbackList,
    userLoyaltyLevelList,
    pagoMovilRefund,
    directDebitTransaction,
    trips,
    tripsHistory,
    apiPartnersTripHistoriesFilters,
    apiPartnersTripHistoriesNotAcceptedFilters,
    prospectSettings,
    prospectOnProcess,
    prospectOnVirtualInspection,
    prospectOnVirtualVideoInterview,
    prospectInPersonInspection,
    addressesCategory,
    corporates,
    apiPartnersTripFilters,
    surge,
    bulkPaymentDriver,
    paymentDriverRecord,
    exportTask,
    scheduledTask,
    cmsDriversCategory,
    currency,
    currencyRateUSDVES,
    currencyRateUSDCOP,
    pagoMovilBanksList,
    mobileBankingPaymentMethodConfig,
    pagoMovilBankCityList,
    pagoMovilTransactions,
    walletHistory,
    referralsConfig,
    referAuthenticationList,
    usersDebts
  },
  plugins: [vuexLocal.plugin]
});
